import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class View extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    // Default variables
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.tags = this.tags.tags || []
    this.tags.reference = this.tags.reference || ''
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '_type',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'children',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/views'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/views/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/views/${this._id}`
    })
  }
}
class Views extends API {
  list (options = {}) {
    const output = (views) => {
      return views.map(view => {
        try {
          return new View(view)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    return this.paginateCursor({
      method: 'get',
      url: 'v4/views',
      onProgress: (views) => {
        if (options?.onProgress) options.onProgress(output(views))
      }
    }).then(views => {
      return output(views)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new View(item)
  }
}

export default Views
export {
  View,
  Views
}
