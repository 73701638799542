import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import Vue from 'vue'
import { environmentModel } from './Environments'

class Model extends APIObject {
  static list
  constructor (options) {
    // Init
    super('ML', options)

    // Default variables
    this.__lastStart = null
    this.type = 'model'
    this.tags = this.tags || {}
    this.modules = this.modules || []
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    this.display_name = this.display_name || ''
    this.starting = this.starting || false
    this.path = this.path || ''
    this.stats = this.stats || []

    // Envs
    this.environment = this.environment || {}
    switch (typeof (this.environment)) {
      case 'string':
        break
      case 'object':
        break
      default:
        this.environment = {}
    }

    if (typeof (this.environment) === 'object') {
      environmentModel(this.environment)
    }
  }

  get primaryScoreFunction () {
    return Object.keys(this.scores?.functions || {}).find(key => this.scores?.functions[key]?.primary)
  }

  get primaryScore () {
    return this.functionScore(this.primaryScoreFunction)
  }

  functionScore (func) {
    if (!func) return
    const result = this.scores?.functions[func]?.result
    return (result && `${(result * 100).toFixed(1).replace(/\.0$/, '')}%`) || '-'
  }

  get deployStatus () {
    // TODO handle the other type of deploy_status
    return this.deploy_statuses?.inference_api
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__lastStart',
      '__socketId',
      'tags.table',
      'tags.model_builder',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name',
      'starting',
      'build',
      'fppm',
      'level',
      'cron',
      'type'
    ])
    return obj
  }

  clone () {
    return new Model(_cloneDeep(this.toJSON()))
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v2/models'
    })
  }

  update (key, value, autoSave = true, socketId = true) {
    if (_isEqual(_get(this, key), value)) return
    this.recursiveSet(this, key, value)
    this.refreshSaveQueue(key)
    if ((this.id || this._id) && autoSave) {
      this.__saving = true
      if (!socketId) this.save(socketId)
      else this.__saveDebounced()
    }
    this.__v++
  }

  async save (socketId = true) {
    Vue.$analytics.track('Edit model', {
      model_id: this._id,
      model_type: this.pipeline_id ? 'Pipeline' : 'Standalone'
    })

    return super.save({
      method: 'PUT',
      url: `v2/models/${this._id}`,
      socketId
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v2/models/${this._id}`
    })
  }

  async install () {
    return this.request({
      method: 'PUT',
      url: `v2/models/${this._id}/install`
    })
  }

  async start () {
    try {
      this.starting = true
      await this.request({
        method: 'POST',
        url: `v2/models/${this._id}/start`,
        socketId: false
      })

      // this.__lastStart = response.headers.date
    } catch (err) {
      // We handle 409, cause it should push the request into "started state"
      if (err.status !== 409) {
        throw err
      }
      // this.__lastStart = err.date
    } finally {
      this.starting = false
    }
  }

  async stop () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v2/models/${this._id}/stop`,
      socketId: false
    })
  }

  async stopBuild () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v2/models/${this._id}/build/stop`
    })
  }

  async stopInstall () {
    this.__lastStart = null
    return this.request({
      method: 'DELETE',
      url: `v2/models/${this._id}/install`
    })
  }
}

class Models extends API {
  async list (filter, options = {}) {
    const output = (models) => {
      return models.map(model => {
        try {
          return new Model(model)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    return this.paginateCursor({
      method: 'get',
      url: 'v2/models',
      onProgress: (models) => {
        if (options?.onProgress) options.onProgress(output(models))
      }
    }).then(models => {
      return output(models)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Model(item)
  }
}
export {
  Model,
  Models
}

export default Models
