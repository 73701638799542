import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Event extends APIObject {
  constructor (options) {
    // Init
    super('ML', options)

    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.params = typeof (this.params) === 'object' ? this.params : {}
    this.type = this.type || 'cron'
    this.active = typeof (this.active) === 'boolean' ? this.active : false
    this.subscriptions = this.subscriptions || []
  }

  _filter (object) {
    return _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v2/events'
    })
  }

  // Info: the subsctiptions will not be saved in this call, use the next call for that
  async save () {
    return super.save({
      method: 'PUT',
      url: `v2/events/${this._id}`
    })
  }

  // We have a seperate call to save subscriptions because an event can have multiple subs. But from UI you can only edit one
  async saveSubscription (subscription) {
    return this.request({
      method: 'POST',
      url: `v2/events/${this._id}/subscribe`,
      data: subscription
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v2/events/${this._id}`
    })
  }
}

class Events extends API {
  list (options = {}) {
    const output = (events) => {
      return events.map(event => {
        try {
          return new Event(event)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    return this.paginateCursor({
      method: 'get',
      url: 'v2/events',
      onProgress: (events) => {
        if (options?.onProgress) options.onProgress(output(events))
      }
    }).then(events => {
      return output(events)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Event(item)
  }
}

export default Events

export {
  Event,
  Events
}
