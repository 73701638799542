import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'


class Rule extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      'created_at',
      'updated_at'
    ])
    return obj
  }

  async create () {
    await super.create({
      method: 'POST',
      url: 'v4/rules'
    })
    return this
  }

  async save () {
    await super.save({
      method: 'PUT',
      url: `v4/rules/${this._id}`
    })
    return this
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/rules/${this._id}`
    })
  }
}

class Rules extends API {
  list (options = {}) {
    const output = (rules) => {
      return rules.map(rule => {
        try {
          return new Rule(rule)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    }

    return this.paginateCursor({
      method: 'get',
      url: 'v4/rules',
      onProgress: (rules) => {
        if (options.onProgress) options.onProgress(output(rules))
      }
    }).then(rules => {
      return output(rules)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Rule(item)
  }
}

export default Rules
export {
  Rule,
  Rules
}
