import _get from 'lodash/get'
import _round from 'lodash/round'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'

import Date from '@/shared/filters/Date'
import Period from '@/shared/filters/Period'
import { API } from '@/shared/plugins/Api/API'
import LogStream from './LogStream'

class Job extends API {
  constructor (options) {
    super('DPE')
    this.execution = null
    this.status = null
    this.execution_id = options.execution_id
    this.update(options)
  }

  update (options) {
    this.service = options.service || 'operator'
    this.type = options.type || this.type
    this.id = options.id || this.id
    this.execution_id = options.execution_id || this.execution_id
    this._id = options._id || this._id
    this.created_at = options.created_at || this.created_at
    this.updated_at = options.updated_at || this.updated_at
    this.end_at = options.end_at || this.end_at
    this.fpu = options.fpu || this.fpu
    this.environment = options.environment || {}
    this.display_name = options.display_name || ''
    this.event = options.event || {}

    // Job queue time info
    this.queue = options.queue || this.queue || {}
    this.queue.start_at = this.queue.start_at || null
    this.queue.end_at = this.queue.end_at || null

    // Job build time info
    this.build = options.build || this.build || {}
    this.build.start_at = this.build.start_at || null
    this.build.end_at = this.build.end_at || null

    // Job provisioning time info
    this.provisioning = options.provisioning || this.provisioning || {}
    this.provisioning.start_at = this.provisioning.start_at || null
    this.provisioning.end_at = this.provisioning.end_at || null

    this.status = options.status || this.status
    this.kube_status = options.kube_status || this.kube_status
    this.execution = options.execution || this.execution

    this.metrics = options.metrics || this.metrics || {}
    this.metrics_info = options.metrics_info || this.metrics_info || {}

    return this
  }

  // Load details from server
  async load (force = false) {
    if (_get(this, 'execution.stages') && !force) return
    const status = await this.request({
      method: 'get',
      url: `v3/jobs/${this.execution_id}`,
      params: {
        metrics: true
      }
    })
    this.update(status)
  }

  get formattedExecutionId () {
    return (this.execution_id?.slice(-7)) || '-'
  }

  get isActive () {
    return ['PENDING', 'SUBMITTED', 'QUEUED', 'BUILDING', 'PROVISIONING', 'PROCESSING'].includes(this.status)
  }

  get stopped () {
    return this.status === 'STOPPED'
  }

  get progress () {
    return _get(this, 'execution.progress') ? Math.ceil(parseFloat(_get(this, 'execution.progress'))) : 0
  }

  stage (stageId) {
    const stages = _get(this, 'execution.stages')
    if (!stages) return null
    return stages.find(stage => stage._id === stageId)
  }

  action (stageId, actionId) {
    const stage = this.stage(stageId)
    if (!stage) return null
    return _get(stage, `actions[${actionId}]`)
  }

  get lastExecution () {
    if (!this.startTime) return '-'
    const time = moment().unix() - this.startTime.unix()
    return Period(time)
  }

  //  Queued time + Run time + Build time
  get duration () {
    return this.executionTime
  }

  get executionTime () {
    return this.runTime + this.buildTime + this.queuedTime
  }

  get executionDate () {
    return this.startTime
  }

  get startTime () {
    return Date(this.created_at)
  }

  get endTime () {
    return Date(this.end_at)
  }

  // Run Time
  get runTime () {
    if (!this.runStartTime) return null
    if (this.runEndTime) {
      return moment(this.runEndTime).unix() - moment(this.runStartTime).unix()
    }
    if (this.endTime) {
      return moment(this.endTime).unix() - moment(this.runStartTime).unix()
    }
    return moment().unix() - moment(this.runStartTime).unix()
  }

  get runStartTime () {
    return this.provisioningStartTime
  }

  get runEndTime () {
    if (!_get(this, 'execution.end_time')) return null
    return Date(this.execution.end_time)
  }

  // Queued Time
  get queued () {
    return ['SUBMITTED', 'QUEUED'].includes(this.status)
  }

  get queuedTime () {
    if (!this.queuedStartTime) return null
    if (this.queuedEndTime) {
      return moment(this.queuedEndTime).unix() - moment(this.queuedStartTime).unix()
    }
    // if no queuedEndTime and nature not 'queued' => return null
    if (this.nature !== 'queued') return null
    return moment().unix() - moment(this.queuedStartTime).unix()
  }

  get queuedStartTime () {
    if (!this.queue.start_at) return null
    return Date(this.queue.start_at)
  }

  get queuedEndTime () {
    if (!this.queue.end_at) return null
    return Date(this.queue.end_at)
  }

  // Build Time
  get buildTime () {
    if (!this.buildStartTime) return null
    if (this.buildEndTime) {
      return moment(this.buildEndTime).unix() - moment(this.buildStartTime).unix()
    }
    return moment().unix() - moment(this.buildStartTime).unix()
  }

  get buildStartTime () {
    if (!this.build.start_at) return null
    return Date(this.build.start_at)
  }

  get buildEndTime () {
    if (!this.build.end_at) return null
    return Date(this.build.end_at)
  }

  // Provisioning Time
  get provisioningTime () {
    if (!this.provisioningStartTime) return null
    if (this.provisioningEndTime) {
      return moment(this.provisioningEndTime).unix() - moment(this.provisioningStartTime).unix()
    }
    return moment().unix() - moment(this.provisioningStartTime).unix()
  }

  get provisioningStartTime () {
    if (!this.provisioning.start_at) return null
    return Date(this.provisioning.start_at)
  }

  get provisioningEndTime () {
    if (!this.provisioning.end_at) return null
    return Date(this.provisioning.end_at)
  }

  get resources () {
    if (this.fpu) {
      return `${this.fpu.instances}x${this.fpu.size}`
    }
    return '1x1'
  }

  // Possible return : SUCCESS, FAILED, STOPPED, TIMEOUT, PENDING, PROCESSING, PROVISIONING, QUEUED, SUBMITTED, BUILDING
  get state () {
    if (this.status === 'PROVISIONING') return 'PENDING'
    return this.status
  }

  // Determines the job nature if it's running, in queue or done (past).
  get nature () {
    if (['QUEUED', 'SUBMITTED', 'PENDING'].includes(this.status)) return 'queued'
    if (this.isActive) return 'running'
    return 'past'
  }

  async logs () {
    const service = this.service === 'builder' ? this.service : 'operator'
    return new LogStream(this.id, this.execution_id, service)
  }

  get cpuRamUsage () {
    const cpuRam = ['cpu', 'mem']
    const cpuRamUsage = {
      cpu: {
        max: this.metrics_info?.max_cpu ? _round(this.metrics_info?.max_cpu_usage / this.metrics_info?.max_cpu * 100, 1) : 0,
        usage: {}
      },
      mem: {
        max: this.metrics_info?.max_mem ? _round(this.metrics_info?.max_mem_usage / this.metrics_info?.max_mem * 100, 1) : 0,
        usage: {}
      }
    }

    // We block max to 100%
    if (cpuRamUsage.cpu.max > 100) cpuRamUsage.cpu.max = 100
    if (cpuRamUsage.mem.max > 100) cpuRamUsage.mem.max = 100

    for (const metricKey in this.metrics) {
      cpuRam.forEach(metric => {
        const chart = []
        let max = 0
        let min = 100
        let sumValue = 0
        const maxValue = parseFloat(this.metrics[metricKey][`max_${metric}`])
        const startTimeUnix = this.startTime.unix()
        const endTimeUnix = this.runEndTime?.unix()

        if (this.metrics[metricKey][metric].length > 0) {
          this.metrics[metricKey][metric].forEach(m => {
            const value = parseFloat(m.value)
            const valuePercent = _round(value / maxValue * 100, 1)
            if (valuePercent > max) max = valuePercent
            if (valuePercent < min) min = valuePercent
            const timeInSecondFromStart = m.timestamp - startTimeUnix
            chart.push([timeInSecondFromStart, valuePercent])
            sumValue += valuePercent
          })
          chart.unshift([0, 0])
          chart.push([endTimeUnix - startTimeUnix, chart[chart.length - 1][1]])
          cpuRamUsage[metric].usage[metricKey] = {
            maxToReach: this.metrics[metricKey][`max_${metric}`],
            chart,
            max,
            min,
            avgPercent: _round(sumValue / this.metrics[metricKey][metric].length, 1)
          }
        } else {
          cpuRamUsage[metric].usage[metricKey] = {
            maxToReach: this.metrics[metricKey][`max_${metric}`],
            chart,
            max: 0,
            min: 0,
            avgPercent: 0
          }
        }
      })
    }

    return cpuRamUsage
  }

  get cpuUsage () {
    return this.cpuRamUsage.cpu
  }

  get ramUsage () {
    return this.cpuRamUsage.mem
  }

  async stop () {
    return this.request({
      method: 'DELETE',
      url: `v3/jobs/${this.type}/${this.execution_id}/stop`
    })
  }
}

class Jobs extends API {
  async list (options = {}) {
    const output = (jobs) => {
      const byExecId = {}
      _sortBy(jobs, ['created_at']).forEach(job => {
        byExecId[job.execution_id] = byExecId[job.execution_id] ? byExecId[job.execution_id].update(job) : new Job(job)
      })

      return Object.values(byExecId)
    }

    const allJobs = await this.paginateCursor({
      method: 'get',
      url: 'v3/jobs',
      onProgress: (jobs) => {
        if (options?.onProgress) options.onProgress(output(jobs))
      }
    })

    return output(allJobs)
  }
}

export default Jobs

export {
  Jobs,
  Job
}
